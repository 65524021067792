(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageTokens', [
			'$http',
			'seedcodeCalendar',
			'utilities',
			manageTokens,
		]);
	function manageTokens($http, seedcodeCalendar, utilities) {
		return {
			getTokens: getTokens,
			createToken: createToken,
		};

		function getTokens(callback) {
			var config = seedcodeCalendar.get('config');
			var userID = config.userID;
			var groupID = config.groupID;
			var tokenID = utilities.generateUID();

			$http({
				method: 'GET',
				url: _CONFIG.DBK_TOKENS_URL,
				data: {
					userID: userID,
					groupID: groupID,
					tokenID: tokenID,
				},
			})
				.then(function (response) {
					if ((!response || !response.data) && callback) {
						callback(false);
						return;
					}
					if (callback) {
						console.log('response', response);
						callback(response.data);
					}
				})
				.catch(function (error) {
					//Run something on error
					if (callback) {
						callback(false);
					}
				});
		}

		function createToken(callback) {
			var config = seedcodeCalendar.get('config');
			var userID = config.userID;
			var groupID = config.groupID;
			var tokenID = utilities.generateUID();

			$http({
				method: 'POST',
				url: _CONFIG.DBK_TOKENS_URL,
				data: {
					userID: userID,
					groupID: groupID,
					tokenID: tokenID,
				},
			})
				.then(function (response) {
					console.log('response', response);
					if ((!response || !response.data) && callback) {
						callback(false);
						return;
					}
					if (callback) {
						callback(response.data);
					}
				})
				.catch(function (error) {
					//Run something on error
					if (callback) {
						callback(false);
					}
				});
		}
	}
})();
